import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import "../components/layout.css"

const NotFoundPage = () => (
  <div className="home_layout">
    <Seo title="404: Not found" />
    <div className="home_cont">
      <h1>404: Not Found</h1>
      <p>Page Not Found</p>
    </div>

    <footer>
      <p>powered by</p>
      <Link to="/">LINKCUBED</Link>
    </footer>
  </div>
)

export default NotFoundPage
